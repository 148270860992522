<template>
  <div class="modal-callback">
    <div class="modal-callback__head">
      <div class="modal-callback__title">Позвонить</div>
    </div>
    <div class="modal-callback__info">Номер заявки <span>№1256</span></div>
    <div class="modal-callback__services">
      <div class="modal-callback__service">
        <div class="modal-callback__service-icon"></div>
        <div class="modal-callback__service-name">Медицинская помощь</div>
      </div>
    </div>
    <div class="modal-callback__foot">
      <button type="button" class="btn btn--prime">Позвонить</button>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>

// modal-evaluate
.modal-callback {
  width: 100%;
  max-width: 400px;
  .modal-callback__head {
    padding: 45px 28px 18px;
    text-align: center;
  }
  .modal-callback__foot {
    padding: 20px 28px 30px;
  }
  .modal-callback__title {
    color: #000000;
    font-weight: 600;
    font-size: 19px;
  }
  .modal-callback__info {
    padding: 16px 28px 14px;
    color: #A4A4A4;
    font-size: 15px;
    border-top: 1px solid rgba(#000000, 0.2);
    border-bottom: 1px solid rgba(#000000, 0.2);
    span {
      color: #000000;
    }
  }
  .modal-callback__services {
    padding: 16px 28px 6px;
    border-bottom: 1px solid rgba(#000000, 0.2);
    .modal-callback__service {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .modal-callback__service-icon {
        position: relative;
        flex-shrink: 0;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        &:after {
          content: "";
          position: absolute;
          left: 11px;
          top: 7px;
          width: 6px;
          height: 12px;
          border: 1px solid var(--color-prime);
          border-left: 0;
          border-top: 0;
          transform: rotate(45deg);
          transform-origin: center;
        }
      }
      .modal-callback__service-name {
        color: rgba(2, 2, 2, 0.9);
        font-size: 17px;
      }
    }
  }
  .btn {
    width: 100%;
  }
}


</style>
