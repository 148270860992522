<template>
  <div class="page-content">
    <div class="container">

      <FilterBtnList
        responsive
      ></FilterBtnList>

      <Breadcrumbs :items="breadcrumbsList"></Breadcrumbs>

      <template v-if="loading">

        <v-progress-linear
          indeterminate
        ></v-progress-linear>

      </template>

      <template v-else-if="Object.keys(response).length">

        <div class="panel-request">
          <div class="panel-request__block">

            <h3 class="panel-request__title">Исполнитель</h3>

            <BlockPerson
              :person="normalizeResponse"
            />

            <div class="panel-request__item">
              <div class="ic-ell">
                <div class="ic-ell__icon">
                  <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.5 0C2.436 0 0 2.48 0 5.6 0 9.76 5.5 16 5.5 16S11 9.76 11 5.6C11 2.48 8.564 0 5.5 0zm0 7.6c-1.1 0-1.964-.88-1.964-2s.864-2 1.964-2 1.964.88 1.964 2-.864 2-1.964 2z" fill="#0C72D6"/></svg>
                </div>
                <div class="ic-ell__text">{{ normalizeResponse.distance }} км от вас</div>
              </div>
            </div>
            <div class="panel-request__item">
              <div class="ic-ell">
                <div class="ic-ell__icon">
                  <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.421 8.333v.556C10.421 9.5 9.9 10 9.263 10H1.158C.52 10 0 9.5 0 8.889V1.11C0 .5.521 0 1.158 0h8.105c.637 0 1.158.5 1.158 1.111v.556h-5.21c-.637 0-1.158.5-1.158 1.11v4.445c0 .611.52 1.111 1.158 1.111h5.21zm-5.21-1.11H11V2.777H5.21v4.444zm2.315-1.39c-.463 0-.868-.389-.868-.833 0-.444.405-.833.868-.833.463 0 .869.389.869.833 0 .444-.406.833-.869.833z" fill="#0C72D6"/></svg>
                </div>
                <div class="ic-ell__text">Стоимость: {{ normalizeResponse.price }} руб./день</div>
              </div>
            </div>
            <div class="panel-request__item">
              <div class="panel-request__field">
                <div class="panel-request__field-prop">Дата рождения</div>
                <div class="panel-request__field-value">{{ normalizeResponse.birthday }}</div>
              </div>
            </div>
            <div class="panel-request__item">
              <div class="panel-request__about">
                <div class="panel-request__label">О себе</div>
                <div class="panel-request__text">{{ normalizeResponse.about }}</div>
              </div>
            </div>
          </div>
          <div class="panel-request__block">
            <div
              v-if="!response.status"
              class="page-content__btn-gp page-content__btn-gp--spb">

              <button
                type="button"
                class="btn btn--border-del"
                @click="cancel"
              >

                <v-progress-circular
                  v-if="loadingBtn === 'cancel'"
                  indeterminate
                  color="#1076da"
                ></v-progress-circular>

                <template v-else>Отклонить</template>

              </button>

              <button
                type="button"
                class="btn btn--border-acs"
                @click="accept"
              >

                <v-progress-circular
                  v-if="loadingBtn === 'accept'"
                  indeterminate
                  color="#1076da"
                ></v-progress-circular>

                <template v-else>Принять</template>

              </button>
            </div>
          </div>
        </div>
        <div class="page-content__btn-gp">
          <v-dialog v-model="dialogCallback">
<!--            <template v-slot:activator="{ on, attrs }">-->
<!--              <button type="button" class="btn btn&#45;&#45;prime" v-on="on" v-bind="attrs">Позвонить</button>-->
<!--            </template>-->
            <v-card>
              <div class="btn-close" @click="dialogCallback = false">
                <svg width="12" height="11" viewBox="0 0 12 11" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.008 9.375a.71.71 0 000 .992.71.71 0 00.992 0L6 6.36l4.008 4.008a.7.7 0 00.984 0 .696.696 0 000-.992l-4-4.008 4-4a.696.696 0 000-.992.7.7 0 00-.984 0L6 4.383 2 .375a.71.71 0 00-.992 0 .71.71 0 000 .992l4.008 4-4.008 4.008z"/></svg>
              </div>
              <ModalCallback />
            </v-card>
          </v-dialog>
        </div>
      </template>
    </div>

    <div class="page-content__footer">
      <v-dialog v-model="dialogSupport">
        <template v-slot:activator="{ on, attrs }">
          <div class="page-content__link" v-on="on" v-bind="attrs">Техническая поддержка</div>
        </template>
        <v-card>
          <div class="btn-close" @click="dialogSupport = false">
            <svg width="12" height="11" viewBox="0 0 12 11" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.008 9.375a.71.71 0 000 .992.71.71 0 00.992 0L6 6.36l4.008 4.008a.7.7 0 00.984 0 .696.696 0 000-.992l-4-4.008 4-4a.696.696 0 000-.992.7.7 0 00-.984 0L6 4.383 2 .375a.71.71 0 00-.992 0 .71.71 0 000 .992l4.008 4-4.008 4.008z"/></svg>
          </div>
          <ModalSupport />
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>

import Breadcrumbs from "@component/UI/Breadcrumbs";
import FilterBtnList from "@component/Module/NeedServiceRequestList/FilterBtnList";
import BlockPerson from '@component/Module/BlockPerson';
import ModalCallback from "@component/Modal/ModalCallback";
import ModalSupport from "@component/Modal/ModalSupport";
import { mapState } from "vuex";
import {
  ASSISTANCE_NEED_APPLICATION,
  ASSISTANCE_NEED_APPLICATION_RESPONSES,
  ASSISTANCE_NEED_RESPONSES
} from "@router/variable";

import { preparePhoto } from '@library/helpers';

export default {
  components: {
    FilterBtnList,
    BlockPerson,
    ModalCallback,
    ModalSupport,
    Breadcrumbs
  },
  data() {
    return {
      dialogSupport: false,
      dialogCallback: false,
      response: {},
      loading: true,
      loadingBtn: null,
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    normalizeResponse() {
      if (!Object.keys(this.response).length) return {};
      const {
        user: {
          first_name,
          last_name,
          birthday,
          photo,
          about,
          rate_avg,
          distance
        }
      } = this.response;

      return {
        name: `${first_name} ${last_name}`,
        birthday,
        image: preparePhoto(photo),
        price: this.response.price,
        about,
        rate: rate_avg,
        distance
      };
    },
    breadcrumbsList() {
      return [
        {
          text: 'Все отклики',
          disabled: false,
          exact: true,
          to: { name: this.$routeName.ASSISTANCE_NEED_RESPONSES },
        },
        {
          text: `Отклики заявки № ${this.$route.params.applicationId}`,
          exact: true,
          to: { name: this.$routeName.ASSISTANCE_NEED_RESPONSES, query: { request: this.$route.params.applicationId } },
        },
        {
          text: this.normalizeResponse?.name ?? `Отклик №${ this.$route.params.responseId }`,
          exact: true,
          disabled: true
        },
      ];
    }
  },
  methods: {
    async startAction(action) {
      this.loadingBtn = action;
      let isSuccess = false;
      try {
       await RequestManager.Service[`${action}Response`](
          this.user.id,
          this.response.request.id,
          this.response.id
        );
        isSuccess = true;
      } catch (err) {
        this.$modal.show('ErrorMessage');
      } finally {
        this.loadingBtn = null;
      }
      return isSuccess;
    },
    async accept() {
      this.$modal.show(
        'Confirm',
        {
          title: 'Вы уверены что хотите принять данный отклик?',
          text: ''
        })
          .then(res => {

            if (!res) return;

            return this.startAction('accept');
          })
          .then(res => {

            if (!res) return;

            this.$router.push({
              name: this.$routeName.ASSISTANCE_NEED_APPLICATION,
              params: {
                applicationId: this.$route.params.applicationId
              }
            });
      });
    },
    async cancel() {
      this.$modal.show(
        'Confirm',
        {
          title: 'Вы уверены что хотите отклонить данный отклик?',
          text: ''
        })
          .then(res => {

            if (!res) return;

            return this.startAction('cancel');
          })
          .then(res => {

            if (!res) return;

            this.$router.push({
              name: this.$routeName.ASSISTANCE_NEED_APPLICATION_RESPONSES,
              params: {
                applicationId: this.$route.params.applicationId
              }
            });

          });
    }
  },
  async mounted() {
    const { responseId, applicationId } = this.$route.params;
    this.response = await RequestManager.Service.getResponse(this.user.id, applicationId, responseId);
    this.loading = false;
  }
};
</script>

<style lang="scss" scoped>

// panel-request
.panel-request {
  position: relative;
  margin-bottom: 40px;
  padding: 21px 27px 21px;
  background-color: #FFFFFF;
  border-radius: 10px;
  border: 1px solid rgba(118, 118, 128, 0.12);
  .block-person {
    margin-top: 10px;
    margin-bottom: 18px;
  }
  .panel-request__num {
    margin-bottom: 9px;
    color: rgba(2, 2, 2, 0.3);
    font-size: 15px;
    span {
      color: #020202;
    }
  }
  .panel-request__title {
    margin-bottom: 19px;
    color: #020202;
    font-size: 17px;
    font-weight: 600;
  }
  .panel-request__value {
    color: #0C76DA;
    font-size: 15px;
  }
  .panel-request__label {
    margin-bottom: 15px;
    color: #000000;
    font-size: 17px;
  }
  .panel-request__text {
    color: rgba(0, 0, 0, 0.4);
    font-size: 17px;
  }
  .panel-request__block {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
    .panel-request__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(163, 163, 163, 0.3);
    }
  }
  .panel-request__about {
    padding: 20px 0 10px;
  }
  .panel-request__descr {
    padding: 16px 0 10px;
  }
  .ic-ell {
    padding: 16px 0;
  }
  .panel-request__field {
    padding: 11px 0;
    .panel-request__field-prop {
      margin-bottom: 6px;
      color: rgba(0, 0, 0, 0.4);
      font-size: 15px;
    }
    .panel-request__field-value {
      color: #000000;
      font-size: 17px;
    }
  }
}
</style>
